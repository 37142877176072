.spending-widget {
    margin-bottom: 2em;
    background-color: $bg-grey;
    font-size: 1em;
    
    &__head {
        padding: 1em 7%;
        border-bottom: 1px solid #e0e0e0;
        color: #666666;
        text-align: justify;
        -ms-text-align-last: justify;
        text-align-last: justify;

        @media screen and (max-width: 460px) {
            padding: 1em 10px;
        }
        > span {
            display: inline-block;
            vertical-align: middle;
        }
        .state {
            color: $color-text-base;
        }
    }

    &__body {
        padding: 2em 0;
        text-align: center;
    }

    &__title {
        font-size: 1.5em;
        font-weight: 500;
    }

    &__item {
        position: relative;
        margin: 1.5em 0;
        padding-top: 1.5em;
        &::before {
            content: '';
            display: block;
            width: 8.125em;
            height: 1px;
            position: absolute;
            top: 0;
            left: 50%;
            @include transform(translateX(-50%));
            background-color: #e0e0e0;
        }
    }

    &__item-title {
        font-size: .875em;
        text-transform: uppercase;
    }

    &__graphic {
        margin: 1em 0;
        &_blue {
            .progress {
                .bar {
                    border-bottom-color: $color-text-blue;
                    border-right-color: $color-text-blue;
                }
                .progress-val {
                    color: $color-text-blue;
                }
            }
        }
        &_yellow {
            .progress {
                .bar {
                    border-bottom-color: $color-yellow;
                    border-right-color: $color-yellow;
                }
                .progress-val {
                    color: $color-yellow;
                }
            }
        }
    }

    &__note {
        vertical-align: middle;
        &::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: .75em;
            height: .75em;
            margin-right: 1em;
            margin-top: -.25em;
        }
        &_blue {
            &::before {
                background-color: $color-text-blue;
            }
        }
        &_yellow {
            &::before {
                background-color: $color-yellow;
            }
        }
    }
    
}