.subscription {
    width: 100%;
    height: auto;
    padding: 2.5em 1em 2.5em 1em;
    background-color: $bg-grey;

    font-size: 1em;

    form {
        @media screen and (max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .label-wrap {
        float: left;
        width: 23%;
        height: 2.875em;
        padding-top: .7em;
        padding-right: .5em;

        color: $color-text-base;
        font-weight: 500;
        text-align: center;
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            height: auto;
            margin-bottom: 2em;
            padding: 0 10px;
        }
    }

    .form-group {
        float: left;
        width: 54%;
        margin-bottom: 0;
        input[type="text"] {
            background-color: $color-text-img;
            border-color: $color-text-base;
        }
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            margin-bottom: 1.44444444em;
        }
    }

    .btn-wrap {
        float: left;
        width: 23%;
        padding-left: 1.875em;
        @media screen and (max-width: 991px) {
            float: none;
            width: 100%;
            padding-left: 0;
            text-align: center;
        }
        .btn {
            width: auto;
            @media screen and (max-width: 991px) {
                width: 100%;
                max-width: 15em;
            }
        }
    }

}