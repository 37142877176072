.home-calendar-wrap {
    margin-bottom: 2.5em;
}

.home-datepicker {

    .ui-datepicker {
        width: 100%;
    }

}

.ui-datepicker {
    z-index: 1001 !important;
    padding: 0;
    background-color: $dp-bg;
    border: 1px solid $dp-border;

    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);

    .ui-datepicker-header {
        border-bottom: 1px solid $dp-border;
    }

    .ui-datepicker-title {
        line-height: 2.5;
        font-weight: 700;
        color: $color-text-blue;
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        top: 50%;
        @include transform(translateY(-50%));
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;
        .ui-icon {
            display: none;
        }
    }
    .ui-datepicker-prev {
        left: .5em;
        background-image: url("../../icons/arrow-big-left.svg");
    }
    .ui-datepicker-next {
        right: .5em;
        background-image: url("../../icons/arrow-big-right.svg");
    }

    table {
        margin-bottom: 0;
        border-bottom: 1em solid $dp-bg;
        background-color: $dp-bg;
    }

    th {
        font-weight: 600;
    }

    td {
        padding: 0;
        a, span {
            text-align: center;
            width: 2.5em;
            height: 2.5em;
            line-height: 2.5em;
            padding: 0;
            margin: 0;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }
        a {
            text-align: center;
            &:hover {
                background-color: $color-text-blue;
                color: $color-text-img;
            }
        }
        &.ui-datepicker-today {
            a, span {
                background-color: $color-text-blue;
                color: $color-text-img;
            }
        }
        &.ui-datepicker-other-month {
            color: $color-secondary;
        }
    }

}