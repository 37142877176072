.main-footer {
    margin-top: -360px;
    overflow: hidden;
    .footer-wrap {

    }

    .footer-nav-wrap, .footer-info-wrap {
        padding-left: 4em;
        @media screen and (max-width: 991px) {
            padding-left: 0;
        }
        .row {
            margin-right: 0;
            margin-left: 0;
            .col-md-3 {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .footer-nav-wrap {
        position: relative;
        border-top: 1px solid $bg-border;
        overflow-x: hidden;
        overflow-y: visible;
        @media screen and (max-width: 991px) {
            border-top: none;
        }
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $bg-border;
        }
        .btn-footer-nav {
            display: block;
            width: 4em;
            height: 2.5em;
            position: absolute;
            top: 0;
            left: 0;
            line-height: 2.2em;
            color: $color-text-blue;
            text-align: center;
            cursor: pointer;
            @media screen and (max-width: 991px) {
                position: static;
                margin: 0 auto;
            }
            &.active {
                .icons {
                    @include transition(all, .2s, ease-in-out);
                    @include transform(rotate(180deg));
                }
            }
        }
    }

    .nav-list {
        @media screen and (max-width: 991px) {
            text-align: center;
        }
        &__title {
            position: relative;
            cursor: pointer;
            &::before {
                content: '';
                display: block;
                width: 250%;
                height: 1px;
                position: absolute;
                z-index: 10;
                bottom: 0;
                left: 50%;
                @include transform(translateX(-50%));
                background-color: $bg-border;
            }
            &.bottom-line {
                &::after {
                    z-index: 11;
                    background-color: $color-text-blue;
                    @media screen and (max-width: 991px) {
                        left: 50%;
                        @include transform(translateX(-50%));
                    }
                }
            }
            .title-text {
                display: table-cell;
                vertical-align: middle;
                height: 3.333333em;
                //line-height: 3.333333em;
                //font-family: $font-family-FuturaPT;
                font-size: .75em;
                font-weight: 500;
                text-transform: uppercase;
                @media screen and (max-width: 991px) {
                    display: block;
                    height: auto;
                    padding: 1em 0;
                }
            }
        }
        &__nav {
            display: none;
            ul {
                padding: 1em 0;
                li {
                    a {
                        display: inline-block;
                        vertical-align: top;
                        padding: .5em 1.5em .5em 0;
                        //font-family: $font-family-ptsc;
                        font-size: .75em;
                        color: $f-color-text-sub-menu;
                        @media screen and (max-width: 991px) {
                            padding-right: 0;
                        }
                        &:hover {
                            color: $color-text-blue;
                        }
                    }
                }
            }
        }
    }

    .footer-info-wrap {
        position: relative;
        padding-top: 2em;
        padding-bottom: 2em;
        @media screen and (max-width: 991px) {
            text-align: center;
        }
        .coat-of-arms {
            position: absolute;
            left: .4em;
            top: 50%;
            @include transform(translateY(-50%));
            font-size: 2.8em;
            line-height: .75;
            color: $color-yellow;
            @media screen and (max-width: 991px) {
                position: static;
                @include transform(none);
                margin-bottom: .4em;
            }
        }
        .footer-info-table {
            display: table;
            width: 100%;
            @media screen and (max-width: 991px) {
                display: block;
            }
            .footer-info-cell {
                display: table-cell;
                //width: 25%;
                vertical-align: middle;
                @media screen and (max-width: 991px) {
                    display: block;
                    margin-bottom: 1em;
                }
            }

        }
        .min-fin-text {
            padding-left: 15px;
            padding-right: 10px;
            color: #757575;
            //font-family: $font-family-ptsc;
            font-size: .875em;
        }
        .previous-site-version{
            display: inline-block;
            font-size: .875em;
            padding-top: 2.5em;
            padding-left: 15px;
            padding-right: 10px;

            a {
                color: $color-text-blue;
                font-weight: 500;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .mail-list {
            //font-family: $font-family-ptsc;
            //font-size: 1em;
            margin-bottom: .8em;
            font-weight: 500;
            color: $color-text-blue;
            li {
                margin: 0;
            }
        }
        .phone-list {
            font-size: 1.125em;
            white-space: nowrap;
            @media screen and (max-width: 991px) {
                font-size: 1.5em;
            }
        }
        .social-list {

            @media screen and (max-width: 991px) {
                padding: 1em;
            }

            > a {
                margin-right: 0;
            }
        }
        .btn-wrap {
            padding-left: 2em;
            white-space: nowrap;
            @media screen and (max-width: 991px) {
                padding-left: 0;
                white-space: normal;
            }
            .btn {
                vertical-align: middle;
                @media screen and (max-width: 1199px) {
                    padding: 0.526em 1em 0.725em;
                }
                @media screen and (max-width: 991px) {
                    width: 100%;
                    max-width: 15em;
                    margin: 0 1em 1.5em 1em;
                }
                &:first-child {
                    margin-right: 2em;
                    @media screen and (max-width: 1199px) {
                        margin-right: 1em;
                    }
                    @media screen and (max-width: 991px) {
                        margin-right: 1em;
                    }
                }
            }
        }
    }

    .modal {
        .external-links-table{
            .external-link-cell {
                padding: 0.5em 0;
                text-align: center;
            }
        }
    }
    .external-links-wrap {
        padding: 2.0625em 1.5em;
        background-color: $bg-grey;
        @media screen and (max-width: 991px) {
            text-align: center;
        }
        .external-links-table {
            display: table;
            width: 100%;
            table-layout: fixed;
            @media screen and (max-width: 991px) {
                display: block;
            }
            .external-link-cell {
                display: table-cell;
                text-align: left;
                vertical-align: middle;
                padding-right: 1em;

                @media screen and (max-width: 991px) {
                    display: block;
                    text-align: center;
                }
            }
            .external-link {
                position: relative;
                display: inline-block;
                padding-left: 1em;
                font-size: .875em;
                color: $color-text-blue;
                @media screen and (max-width: 991px) {
                    padding: .5em 0;
                    font-size: 1em;
                    font-weight: 500;
                }
                &:hover {
                    color: $color-text-base;
                }
                &::after {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 2.5em;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    @include transform(translateY(-50%));
                    background-color: $bg-border;
                    @media screen and (max-width: 991px) {
                        content: none;
                    }
                }
            }
        }
    }
    .footer-bottom {
        padding: 2.5em 0 3em 0;
        //font-family: $font-family-ptsc;
        font-size: .75em;
        color: #757575;
        text-align: center;
    }
}