.mCSB_scrollTools {

    &.mCS-select-chosen {

        .mCSB_dragger {
            .mCSB_dragger_bar {
                width: 8px;
                background-color: $dg-scrollbar-el;
            }
        }

        .mCSB_draggerRail {
            width: 0;
        }

    }

}

.mCS-select-chosen {

    &.mCSB_inside {
        > .mCSB_container {
            margin-right: 0;
        }
    }

}