.pagination {
    font-size: 1em;
    padding: 2.5em 0;
    text-align: center;
    @media screen and (max-width: 767px) {
        padding-top: 1.5em;
    }

    &__nav {
        position: relative;
        @media screen and (max-width: 767px) {
            padding-top: 4em;
        }
    }

    &__link {
        display: inline-block;
        vertical-align: top;
        padding: .5em 1em;
        border: 1px solid $color-secondary;
        font-size: .875em;
        color: $color-text-grey;
        &:hover {
            border-color: $color-text-blue;
            color: $color-text-blue;
        }
        &.active {
            border-color: $color-text-blue;
            color: $color-text-blue;
        }
        &_prev, &_next {
            border: none;
            padding-top: .35em;
            @media screen and (max-width: 767px) {
                position: absolute;
                top: 0;
            }
            > * {
                vertical-align: middle;
            }
            .icons {
                padding: 0 .5em;
            }
        }
        &_prev {
            @media screen and (max-width: 767px) {
                left: 0;
            }
        }
        &_next {
            @media screen and (max-width: 767px) {
                right: 0;
            }
        }
    }

}