.list-common {
    margin-bottom: 3.125em;
    list-style: none;
    counter-reset: item;

    &__item {
        position: relative;
        padding-left: 1.8em;
        margin-bottom: 1.25em;

        @media screen and (max-width: 991px) {
            padding-left: 1.5em;
        }

        &::before {
            content: counter(item);
            counter-increment: item;
            position: absolute;
            top: .06em;
            left: 0;
            color: $color-text-grey;
        }
    }

    &__item-text {
        margin-bottom: .35em;
        font-weight: 500;
        color: $color-text-blue;
        a {
            font-weight: 500;
            color: $color-text-blue;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__item-date {
        @extend %date;
        display: inline-block;
        vertical-align: bottom;
        margin-right: .8em;

        @media screen and (max-width: 991px) {
            margin-right: .6em;
        }

        @media screen and (max-width: 580px) {
            display: block;
            margin-bottom: .5em;
        }

        .text {
            &::after {
                content: none;
            }
        }
    }

    &__item-download {
        position: relative;
        padding-left: 1em;
        display: inline-block;
        vertical-align: middle;

        @media screen and (max-width: 991px) {
            padding-left: .8em;
        }

        @media screen and (max-width: 580px) {
            padding-left: 0;
        }

        &::after {
            content: '';
            display: block;
            width: 1px;
            height: 80%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $color-text-grey;
            @media screen and (max-width: 580px) {
                content: none;
            }
        }
        .btn-link {
            padding-left: 0;
            background-repeat: no-repeat;
            background-position: left center;
            background-size: contain;
            vertical-align: middle;
            @extend %round-link-icon;
            .icons {
                position: static;
                margin-right: .25em;
                @include transform(none);
                &::before {
                    @include transform(translateX(-.22em));
                }
            }
            .text-link {
                display: inline-block;
                vertical-align: middle;
                text-decoration: inherit;
            }
            &[href$=".doc"], &[href$=".rtf"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-doc.svg");
                &:hover {
                    background-image: url("../../icons/file-doc-hover.svg");
                }
            }
            &[href$=".jpg"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-jpg.svg");
                &:hover {
                    background-image: url("../../icons/file-jpg-hover.svg");
                }
            }
            &[href$=".tiff"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-tiff.svg");
                &:hover {
                    background-image: url("../../icons/file-tiff-hover.svg");
                }
            }
            &[href$=".ppt"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-ppt.svg");
                &:hover {
                    background-image: url("../../icons/file-ppt-hover.svg");
                }
            }
            &[href$=".xls"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-xls.svg");
                &:hover {
                    background-image: url("../../icons/file-xls-hover.svg");
                }
            }
            &[href$=".zip"], &[href$=".7z"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-zip.svg");
                &:hover {
                    background-image: url("../../icons/file-zip-hover.svg");
                }
            }
            &[href$=".rar"] {
                padding-left: 2.5em;
                background-image: url("../../icons/file-rar.svg");
                &:hover {
                    background-image: url("../../icons/file-rar-hover.svg");
                }
            }
        }
    }

}