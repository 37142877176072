

// Typography
$font-family-base: ProbaPro, Arial, Helvetica, Verdana, sans-serif;
$font-size-base: 16;
$line-height-base: 1.25;


// Colors Text
$color-text-base: #333333; /*base text on the site*/
//$color-text-grey: #999999; /*base text hover*/
$color-text-grey: #757575;
$color-text-img: #ffffff; /*text on dark background*/

$color-secondary: #cccccc; /*secondary text on the site*/

$color-table-icon: #666666;

$color-text-blue: #2d5ca6;
$color-remark: #ff0000;
$color-yellow: #fbd900;


// Header
$color-header-text: #ffffff;
$color-header-text-hover: #fbd900;
$color-header-text-secondary: #D5DAE2;
$color-btns-icon: #4d4d4d;
$color-header-social: #aab3c6;
$color-gov-link-sub-text: #f9f9f9;
$color-header-secondary: #cccccc;
$color-menu-side-search-count: #6d727c;
$border-t-b-nav-btns: #fbfbfb;
$border-top-menu-mobile: #939393;
$bg-header-sub-menu: #273043;
$bg-header-mobile: #284d81;




// Btns
$bg-btn-blue: #2d5ca6;
$bg-btn-blue-hover: #234161;
$bg-btn-yellow: #ffe358;
$bg-btn-yellow-hover: #f6cd00;

$border-btn-active: #4085bf;



// Sliders
$bg-active-dots: #d4d5d8;



// Background Color
$bg-grey: #f5f7fa;
$bg-border: #bababa;
$table-border: #d5d5d5;
$bg-table-strip: #f5f7fa;
$bg-table-strip-odd: #fdfdfe;
$bg-table-strip-hover: #c0d6f7;
$bg-default-card-img: #c2c5cb;
$bg-hr-line: #d5d5d3;
$dg-scrollbar-el: #c2c5cb;
$border-round-link-icon: rgba(34,79,145,.6);


// Footer
$f-color-text-sub-menu: #666666;

// Datepicker
$dp-bg: #ffffff;
$dp-border: rgba(144,164,174,.15);
//$dp-color-text-disable: #7f898d;

// Form
$color-text-secondary-form: #6d727c;
$border-color-form: #6d727c;
$border-active-input: #4085bf;
$disabled-form: #c2c5cb;
$disabled-form-active: #c2c5cb;