/*base text on the site*/

/*text on dark background*/

/*secondary text on the site*/

/*===================================================
 Mixins
===================================================*/

@import "~jquery-ui/themes/base/datepicker.css";

.list-common__item-date {
  color: #757575;
}

.list-common__item-date .icons {
  margin-right: 0.5em;
}

.list-common__item-date > * {
  vertical-align: middle;
}

.list-common__item-download .btn-link .icons {
  top: 50%;
  width: 1.1em;
  height: 1.1em;
  border: 2px solid rgba(34, 79, 145, 0.6);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}

.list-common__item-download .btn-link .icons::before {
  font-size: 1.5em;
  -webkit-transform: translateX(-0.25em);
  -moz-transform: translateX(-0.25em);
  -ms-transform: translateX(-0.25em);
  -o-transform: translateX(-0.25em);
  transform: translateX(-0.25em);
}

.modal {
  background: rgba(255, 255, 255, 0.5);
}

.modal .modal-dialog .modal-content {
  border-radius: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.btn {
  display: inline-block;
  vertical-align: top;
  padding: 0.813em 2em;
  outline: none;
  border: 2px solid #2d5ca6;
  background-color: transparent;
  font-size: 1em;
  line-height: 1;
  font-weight: 600;
  color: #2d5ca6;
  text-align: center;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 991px) {
  .btn {
    width: 100%;
    max-width: 15em;
  }
}

.btn:hover {
  border-color: #4085bf;
  color: #4085bf;
}

.btn:focus,
.btn:active {
  background-color: #4085bf;
  border-color: #4085bf;
  color: #ffffff;
}

.btn.fix-height {
  display: table-cell;
  vertical-align: middle;
  height: 2.5em;
  padding: 0 2em;
  line-height: 1;
}

.btn.no-border {
  height: auto;
  padding: 0;
  line-height: 1.25;
  border: none;
}

.btn.no-border:hover,
.btn.no-border:active,
.btn.no-border:focus {
  border: none;
  background-color: transparent;
  color: #2d5ca6;
}

.btn.blue {
  background-color: #2d5ca6;
  border-color: #2d5ca6;
  color: #ffffff;
}

.btn.blue:hover {
  background-color: #234161;
  border-color: #234161;
  color: #ffffff;
}

.btn.blue:focus,
.btn.blue:active {
  background-color: #234161;
  border-color: #4085bf;
  color: #ffffff;
}

.btn.yellow {
  background-color: #ffe358;
  border-color: #ffe358;
  color: #333333;
}

.btn.yellow:hover {
  background-color: #f6cd00;
  border-color: #f6cd00;
  color: #333333;
}

.btn.yellow:focus,
.btn.yellow:active {
  background-color: #f6cd00;
  border-color: #4085bf;
  color: #333333;
}

.btn.full-width {
  width: 100%;
  max-width: none;
}

.btn.pad-4em {
  padding-left: 4em;
  padding-right: 4em;
}

.btn.pad-3em {
  padding-left: 3em;
  padding-right: 3em;
}

.btn__anim {
  position: relative;
}

.btn__anim .line-top-left,
.btn__anim .line-bottom-right {
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

.btn__anim .line-top-left {
  top: -2px;
  left: -2px;
}

.btn__anim .line-bottom-right {
  bottom: -2px;
  right: -2px;
}

.btn__anim .line-top-left:before,
.btn__anim .line-top-left:after,
.btn__anim .line-bottom-right:before,
.btn__anim .line-bottom-right:after {
  content: "";
  position: absolute;
  z-index: 99;
  background: #4085bf;
  -webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn__anim .line-top-left:before {
  height: 0;
  width: 2px;
  left: 0;
  top: 0;
}

.btn__anim .line-top-left:after {
  height: 2px;
  width: 0%;
  left: 0;
  top: 0;
}

.btn__anim .line-bottom-right:before {
  height: 0;
  width: 2px;
  right: 0;
  bottom: 0;
}

.btn__anim .line-bottom-right:after {
  height: 2px;
  width: 0;
  right: 0;
  bottom: 0;
}

.btn__anim:hover .line-top-left:before,
.btn__anim:hover .line-bottom-right:before {
  height: 100%;
}

.btn__anim:hover .line-top-left:after,
.btn__anim:hover .line-bottom-right:after {
  width: 100%;
}

.btn-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  line-height: 1;
  color: #2d5ca6;
}

.btn-link:hover {
  color: #2d5ca6;
  text-decoration: underline;
}

.btn-link .icons {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.2em;
}

.btn-link.under {
  text-decoration: underline;
}

.btn-link.under:hover {
  text-decoration: none;
}

.btn-link.left-icon {
  padding-left: 1.6em;
}

.btn-link.left-icon .icons {
  left: 0;
}

.btn-link.right-icon {
  padding-right: 1.6em;
}

.btn-link.right-icon .icons {
  right: 0;
}

.btn-table-wrap {
  margin-left: -15px;
  margin-right: -15px;
}

@media screen and (max-width: 767px) {
  .btn-table-wrap {
    margin-left: -8px;
    margin-right: -8px;
  }
}

.btn-table-wrap .btn-list {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 1.8em;
}

.btn-table-wrap .btn-table-cel {
  display: table-cell;
  vertical-align: middle;
  height: 6.25em;
  position: relative;
  padding-right: 15px;
  background-color: #f5f7fa;
  border-left: 15px solid #ffffff;
  border-right: 15px solid #ffffff;
  color: #2d5ca6;
  font-size: 1.125em;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .btn-table-wrap .btn-table-cel {
    height: auto;
    padding-top: 1.25em;
    padding-left: 10px;
    padding-right: 10px;
    border-left: 8px solid #ffffff;
    border-right: 8px solid #ffffff;
    vertical-align: top;
    text-align: center;
  }
}

.btn-table-wrap .btn-table-cel:hover span {
  text-decoration: underline;
}

.btn-table-wrap .btn-table-cel span {
  display: inline-block;
  padding-left: 34%;
}

@media screen and (max-width: 767px) {
  .btn-table-wrap .btn-table-cel span {
    width: 100%;
    padding-left: 0;
    margin-bottom: 1.25em;
  }
}

.btn-table-wrap .btn-table-cel .icons {
  position: absolute;
  top: 50%;
  left: 10%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 2.8em;
  color: #666666;
}

@media screen and (max-width: 1199px) {
  .btn-table-wrap .btn-table-cel .icons {
    left: 7%;
    font-size: 2.5em;
  }
}

@media screen and (max-width: 767px) {
  .btn-table-wrap .btn-table-cel .icons {
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    margin-bottom: 10px;
  }
}

.spending-widget {
  margin-bottom: 2em;
  background-color: #f5f7fa;
  font-size: 1em;
}

.spending-widget__head {
  padding: 1em 7%;
  border-bottom: 1px solid #e0e0e0;
  color: #666666;
  text-align: justify;
  -ms-text-align-last: justify;
  text-align-last: justify;
}

@media screen and (max-width: 460px) {
  .spending-widget__head {
    padding: 1em 10px;
  }
}

.spending-widget__head > span {
  display: inline-block;
  vertical-align: middle;
}

.spending-widget__head .state {
  color: #333333;
}

.spending-widget__body {
  padding: 2em 0;
  text-align: center;
}

.spending-widget__title {
  font-size: 1.5em;
  font-weight: 500;
}

.spending-widget__item {
  position: relative;
  margin: 1.5em 0;
  padding-top: 1.5em;
}

.spending-widget__item::before {
  content: "";
  display: block;
  width: 8.125em;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #e0e0e0;
}

.spending-widget__item-title {
  font-size: 0.875em;
  text-transform: uppercase;
}

.spending-widget__graphic {
  margin: 1em 0;
}

.spending-widget__graphic_blue .progress .bar {
  border-bottom-color: #2d5ca6;
  border-right-color: #2d5ca6;
}

.spending-widget__graphic_blue .progress .progress-val {
  color: #2d5ca6;
}

.spending-widget__graphic_yellow .progress .bar {
  border-bottom-color: #fbd900;
  border-right-color: #fbd900;
}

.spending-widget__graphic_yellow .progress .progress-val {
  color: #fbd900;
}

.spending-widget__note {
  vertical-align: middle;
}

.spending-widget__note::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.75em;
  height: 0.75em;
  margin-right: 1em;
  margin-top: -0.25em;
}

.spending-widget__note_blue::before {
  background-color: #2d5ca6;
}

.spending-widget__note_yellow::before {
  background-color: #fbd900;
}

.progress {
  position: relative;
  text-align: center;
}

.progress .barOverflow {
  /* Wraps the rotating .bar */
  position: relative;
  width: 11.25em;
  height: 5.625em;
  /* Half circle (overflow) */
  margin: 0 auto;
  overflow: hidden;
  /* Comment this line to understand the trick */
}

.progress .bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 11.25em;
  height: 11.25em;
  /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 2.125em solid #ffffff;
  /* half gray, */
  border-bottom-color: #2d5ca6;
  /* half azure */
  border-right-color: #2d5ca6;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.progress .progress-val {
  position: absolute;
  left: 50%;
  bottom: 0.6em;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
  transform: translate(-50%);
  font-size: 1.5em;
  font-weight: 700;
  color: #2d5ca6;
}

.progress .progress-note {
  width: 11.25em;
  margin: 0 auto;
  padding-top: 0.125em;
  padding-left: 0.8em;
  padding-right: 0.35em;
  text-align: justify;
  -ms-text-align-last: justify;
  text-align-last: justify;
}

.progress .progress-note span {
  font-size: 0.875em;
}

.subscription {
  width: 100%;
  height: auto;
  padding: 2.5em 1em 2.5em 1em;
  background-color: #f5f7fa;
  font-size: 1em;
}

@media screen and (max-width: 991px) {
  .subscription form {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.subscription .label-wrap {
  float: left;
  width: 23%;
  height: 2.875em;
  padding-top: 0.7em;
  padding-right: 0.5em;
  color: #333333;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .subscription .label-wrap {
    float: none;
    width: 100%;
    height: auto;
    margin-bottom: 2em;
    padding: 0 10px;
  }
}

.subscription .form-group {
  float: left;
  width: 54%;
  margin-bottom: 0;
}

.subscription .form-group input[type=text] {
  background-color: #ffffff;
  border-color: #333333;
}

@media screen and (max-width: 991px) {
  .subscription .form-group {
    float: none;
    width: 100%;
    margin-bottom: 1.44444444em;
  }
}

.subscription .btn-wrap {
  float: left;
  width: 23%;
  padding-left: 1.875em;
}

@media screen and (max-width: 991px) {
  .subscription .btn-wrap {
    float: none;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.subscription .btn-wrap .btn {
  width: auto;
}

@media screen and (max-width: 991px) {
  .subscription .btn-wrap .btn {
    width: 100%;
    max-width: 15em;
  }
}

.table-wrap {
  font-size: 1em;
}

.table-wrap .table-head {
  margin-bottom: 1em;
  text-align: justify;
  -ms-text-align-last: justify;
  text-align-last: justify;
}

@media screen and (max-width: 991px) {
  .table-wrap .table-head {
    text-align: center;
    -ms-text-align-last: center;
    text-align-last: center;
  }
}

.table-wrap .table-head .summary-text {
  display: inline-block;
  vertical-align: bottom;
  color: #757575;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .table-wrap .table-head .summary-text {
    margin-bottom: 0.5em;
  }
}

.table-wrap .table-head .btn-wrap {
  display: inline-block;
  vertical-align: bottom;
}

.table-wrap .table-head .btn-wrap .btn {
  padding-left: 4em;
  padding-right: 4em;
}

@media screen and (max-width: 991px) {
  .table-wrap .table-head .btn-wrap .btn {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media screen and (max-width: 991px) {
  .table-wrap .table-head .btn-wrap {
    display: block;
  }
}

.table-wrap .table-body {
  width: 100%;
  overflow-x: auto;
}

@media screen and (max-width: 991px) {
  .table-wrap .table-body {
    width: auto;
  }
}

table,
.table {
  border-collapse: collapse;
}

table__bordered,
.table__bordered {
  border-collapse: collapse;
}

table__bordered thead,
.table__bordered thead {
  background-color: #f5f7fa;
}

table__bordered thead tr:first-child th,
.table__bordered thead tr:first-child th {
  border-top: none;
}

table__bordered thead th,
.table__bordered thead th {
  border: 1px solid #d5d5d5;
}

table__bordered tbody td,
.table__bordered tbody td {
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
}

table__striped thead,
.table__striped thead {
  background-color: #f5f7fa;
}

table__striped th,
.table__striped th {
  color: #757575;
  font-weight: 500;
}

table__striped tbody tr:nth-child(even),
.table__striped tbody tr:nth-child(even) {
  background-color: #f5f7fa;
}

table__striped.table__hover tbody tr:hover,
.table__striped.table__hover tbody tr:hover {
  background-color: #c0d6f7;
}

table__hover tbody tr:hover,
.table__hover tbody tr:hover {
  background-color: #f5f7fa;
}

table__full,
.table__full {
  width: 100%;
}

@media screen and (max-width: 767px) {
  table__full,
  .table__full {
    width: auto;
  }
}

table th,
table td,
.table th,
.table td {
  padding: 0.5em 0.5em;
  vertical-align: middle;
}

.pagination {
  font-size: 1em;
  padding: 2.5em 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .pagination {
    padding-top: 1.5em;
  }
}

.pagination__nav {
  position: relative;
}

@media screen and (max-width: 767px) {
  .pagination__nav {
    padding-top: 4em;
  }
}

.pagination__link {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 1em;
  border: 1px solid #cccccc;
  font-size: 0.875em;
  color: #757575;
}

.pagination__link:hover {
  border-color: #2d5ca6;
  color: #2d5ca6;
}

.pagination__link.active {
  border-color: #2d5ca6;
  color: #2d5ca6;
}

.pagination__link_prev,
.pagination__link_next {
  border: none;
  padding-top: 0.35em;
}

@media screen and (max-width: 767px) {
  .pagination__link_prev,
  .pagination__link_next {
    position: absolute;
    top: 0;
  }
}

.pagination__link_prev > *,
.pagination__link_next > * {
  vertical-align: middle;
}

.pagination__link_prev .icons,
.pagination__link_next .icons {
  padding: 0 0.5em;
}

@media screen and (max-width: 767px) {
  .pagination__link_prev {
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .pagination__link_next {
    right: 0;
  }
}

.social-list {
  padding: 0 1em;
  white-space: nowrap;
}

.social-list > a {
  display: inline-block;
  width: 1.7em;
  height: 1.7em;
  margin-right: 0.5em;
  text-align: center;
  color: #757575;
}

.social-list > a:hover {
  color: #2d5ca6;
}

.social-list > a:last-child {
  margin-right: 0;
}

.home-calendar-wrap {
  margin-bottom: 2.5em;
}

.home-datepicker .ui-datepicker {
  width: 100%;
}

.ui-datepicker {
  z-index: 1001 !important;
  padding: 0;
  background-color: #ffffff;
  border: 1px solid rgba(144, 164, 174, 0.15);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}

.ui-datepicker .ui-datepicker-header {
  border-bottom: 1px solid rgba(144, 164, 174, 0.15);
}

.ui-datepicker .ui-datepicker-title {
  line-height: 2.5;
  font-weight: 700;
  color: #2d5ca6;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev .ui-icon,
.ui-datepicker .ui-datepicker-next .ui-icon {
  display: none;
}

.ui-datepicker .ui-datepicker-prev {
  left: 0.5em;
  background-image: url("../icons/arrow-big-left.svg");
}

.ui-datepicker .ui-datepicker-next {
  right: 0.5em;
  background-image: url("../icons/arrow-big-right.svg");
}

.ui-datepicker table {
  margin-bottom: 0;
  border-bottom: 1em solid #ffffff;
  background-color: #ffffff;
}

.ui-datepicker th {
  font-weight: 600;
}

.ui-datepicker td {
  padding: 0;
}

.ui-datepicker td a,
.ui-datepicker td span {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  padding: 0;
  margin: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.ui-datepicker td a {
  text-align: center;
}

.ui-datepicker td a:hover {
  background-color: #2d5ca6;
  color: #ffffff;
}

.ui-datepicker td.ui-datepicker-today a,
.ui-datepicker td.ui-datepicker-today span {
  background-color: #2d5ca6;
  color: #ffffff;
}

.ui-datepicker td.ui-datepicker-other-month {
  color: #cccccc;
}

.list-common {
  margin-bottom: 3.125em;
  list-style: none;
  counter-reset: item;
}

.list-common__item {
  position: relative;
  padding-left: 1.8em;
  margin-bottom: 1.25em;
}

@media screen and (max-width: 991px) {
  .list-common__item {
    padding-left: 1.5em;
  }
}

.list-common__item::before {
  content: counter(item);
  counter-increment: item;
  position: absolute;
  top: 0.06em;
  left: 0;
  color: #757575;
}

.list-common__item-text {
  margin-bottom: 0.35em;
  font-weight: 500;
  color: #2d5ca6;
}

.list-common__item-text a {
  font-weight: 500;
  color: #2d5ca6;
}

.list-common__item-text a:hover {
  text-decoration: underline;
}

.list-common__item-date {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 0.8em;
}

@media screen and (max-width: 991px) {
  .list-common__item-date {
    margin-right: 0.6em;
  }
}

@media screen and (max-width: 580px) {
  .list-common__item-date {
    display: block;
    margin-bottom: 0.5em;
  }
}

.list-common__item-date .text::after {
  content: none;
}

.list-common__item-download {
  position: relative;
  padding-left: 1em;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 991px) {
  .list-common__item-download {
    padding-left: 0.8em;
  }
}

@media screen and (max-width: 580px) {
  .list-common__item-download {
    padding-left: 0;
  }
}

.list-common__item-download::after {
  content: "";
  display: block;
  width: 1px;
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #757575;
}

@media screen and (max-width: 580px) {
  .list-common__item-download::after {
    content: none;
  }
}

.list-common__item-download .btn-link {
  padding-left: 0;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  vertical-align: middle;
}

.list-common__item-download .btn-link .icons {
  position: static;
  margin-right: 0.25em;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}

.list-common__item-download .btn-link .icons::before {
  -webkit-transform: translateX(-0.22em);
  -moz-transform: translateX(-0.22em);
  -ms-transform: translateX(-0.22em);
  -o-transform: translateX(-0.22em);
  transform: translateX(-0.22em);
}

.list-common__item-download .btn-link .text-link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: inherit;
}

.list-common__item-download .btn-link[href$=".doc"],
.list-common__item-download .btn-link[href$=".rtf"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-doc.svg");
}

.list-common__item-download .btn-link[href$=".doc"]:hover,
.list-common__item-download .btn-link[href$=".rtf"]:hover {
  background-image: url("../icons/file-doc-hover.svg");
}

.list-common__item-download .btn-link[href$=".jpg"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-jpg.svg");
}

.list-common__item-download .btn-link[href$=".jpg"]:hover {
  background-image: url("../icons/file-jpg-hover.svg");
}

.list-common__item-download .btn-link[href$=".tiff"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-tiff.svg");
}

.list-common__item-download .btn-link[href$=".tiff"]:hover {
  background-image: url("../icons/file-tiff-hover.svg");
}

.list-common__item-download .btn-link[href$=".ppt"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-ppt.svg");
}

.list-common__item-download .btn-link[href$=".ppt"]:hover {
  background-image: url("../icons/file-ppt-hover.svg");
}

.list-common__item-download .btn-link[href$=".xls"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-xls.svg");
}

.list-common__item-download .btn-link[href$=".xls"]:hover {
  background-image: url("../icons/file-xls-hover.svg");
}

.list-common__item-download .btn-link[href$=".zip"],
.list-common__item-download .btn-link[href$=".7z"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-zip.svg");
}

.list-common__item-download .btn-link[href$=".zip"]:hover,
.list-common__item-download .btn-link[href$=".7z"]:hover {
  background-image: url("../icons/file-zip-hover.svg");
}

.list-common__item-download .btn-link[href$=".rar"] {
  padding-left: 2.5em;
  background-image: url("../icons/file-rar.svg");
}

.list-common__item-download .btn-link[href$=".rar"]:hover {
  background-image: url("../icons/file-rar-hover.svg");
}

.more-info.open .more-info__title {
  color: #2d5ca6;
}

.more-info.open .more-info__btn-wrap .btn-link .icons::before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.more-info.open .more-info__btn-wrap .btn-link-open {
  display: none;
}

.more-info.open .more-info__btn-wrap .btn-link-close {
  display: inline-block;
}

.more-info.open .more-info__btn-wrap .btn-link-close .icons::before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.more-info.open .more-info__body {
  display: block;
}

.more-info__title {
  cursor: pointer;
}

.more-info__title:hover {
  color: #2d5ca6;
}

.more-info__title:hover ~ .more-info__btn-wrap .btn-link {
  text-decoration: underline;
}

.more-info__body {
  display: none;
  padding: 1em 0 1em 2em;
}

@media screen and (max-width: 767px) {
  .more-info__body {
    padding-left: 0;
  }
}

.more-info__btn-wrap .btn-link {
  padding-right: 1.3em;
}

.more-info__btn-wrap .btn-link .icons {
  font-size: 0.75em;
}

.more-info__btn-wrap .btn-link-close {
  display: none;
}

.mCSB_scrollTools.mCS-select-chosen .mCSB_dragger .mCSB_dragger_bar {
  width: 8px;
  background-color: #c2c5cb;
}

.mCSB_scrollTools.mCS-select-chosen .mCSB_draggerRail {
  width: 0;
}

.mCS-select-chosen.mCSB_inside > .mCSB_container {
  margin-right: 0;
}

.response-table .table-info .btn {
  padding-left: 4em;
  padding-right: 4em;
}

.structure .depart-info__suit .info-col,
.structure .accordion__head .info-col {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 2.5em;
  padding: 0 0.5em;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col,
  .structure .accordion__head .info-col {
    display: block;
    height: auto;
  }
}

.structure .depart-info__suit .info-col::before,
.structure .accordion__head .info-col::before {
  content: "";
  display: block;
  width: 1px;
  height: 1.5em;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #cccccc;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col::before,
  .structure .accordion__head .info-col::before {
    content: none;
  }
}

.structure .depart-info__suit .info-col:first-child::before,
.structure .accordion__head .info-col:first-child::before {
  content: none;
}

.structure .depart-info__suit .info-col.title,
.structure .accordion__head .info-col.title {
  width: 30%;
  padding-left: 2.8em;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col.title,
  .structure .accordion__head .info-col.title {
    width: 100%;
    margin-bottom: 1em;
  }
}

.structure .depart-info__suit .info-col.title.btn-link,
.structure .accordion__head .info-col.title.btn-link {
  vertical-align: middle;
}

.structure .depart-info__suit .info-col.title.title-full,
.structure .accordion__head .info-col.title.title-full {
  width: 100%;
  height: 3.3em;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col.title.title-full,
  .structure .accordion__head .info-col.title.title-full {
    height: auto;
    margin-bottom: 0;
  }
}

.structure .depart-info__suit .info-col.name,
.structure .accordion__head .info-col.name {
  width: 45%;
  padding-left: 1em;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col.name,
  .structure .accordion__head .info-col.name {
    width: 100%;
    padding-left: 0;
    margin-bottom: 0.5em;
  }
}

.structure .depart-info__suit .info-col.name > span,
.structure .accordion__head .info-col.name > span {
  font-size: 1.25em;
}

.structure .depart-info__suit .info-col.phones-wrap,
.structure .accordion__head .info-col.phones-wrap {
  width: 25%;
  padding-left: 2em;
  white-space: nowrap;
  color: #666666;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col.phones-wrap,
  .structure .accordion__head .info-col.phones-wrap {
    width: 100%;
    padding-left: 0;
  }
}

.structure .depart-info__suit .info-col.phones-wrap .phones,
.structure .accordion__head .info-col.phones-wrap .phones {
  font-size: 0.875em;
  line-height: 1.5;
}

.structure__header {
  margin-bottom: 2em;
}

.structure__header .help-text {
  float: left;
  color: #757575;
  margin-bottom: 1em;
}

@media screen and (max-width: 767px) {
  .structure__header .help-text {
    float: none;
  }
}

.structure__header > .btn {
  float: right;
}

@media screen and (max-width: 767px) {
  .structure__header > .btn {
    float: none;
  }
}

.structure > .accordion > .accordion__item.open {
  border-left: none;
}

.structure > .accordion > .accordion__item.open > .accordion__head {
  background-color: #2d5ca6;
}

.structure > .accordion > .accordion__item > .accordion__head {
  background-color: #2d5ca6;
}

.structure > .accordion > .accordion__item > .accordion__head .info-col {
  height: 2.2em;
}

.structure > .accordion > .accordion__item > .accordion__head .info-col.title {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .structure > .accordion > .accordion__item > .accordion__head .info-col.title {
    margin-bottom: 0;
    height: auto;
    vertical-align: baseline;
  }
}

.structure > .accordion > .accordion__item > .accordion__head .title {
  color: #ffffff;
  width: auto;
}

.structure > .accordion > .accordion__item > .accordion__head .title .icons {
  border: 2px solid rgba(255, 255, 255, 0.6);
}

.structure > .accordion > .accordion__item > .accordion__body .accordion__head {
  padding-top: 1em;
  padding-bottom: 1em;
}

.structure .accordion__item {
  margin-top: 1em;
  margin-bottom: 0;
}

.structure .accordion__item:first-child {
  margin-top: 0;
}

.structure .accordion__item.open {
  border-left: 1px solid #e6ebf4;
}

.structure .accordion__item.open > .accordion__head {
  background-color: #e6ebf4;
}

.structure .accordion__head {
  display: table;
  width: 100%;
}

.structure .accordion__body {
  padding: 1em 0em 0em 2em;
}

@media screen and (max-width: 767px) {
  .structure .accordion__body {
    padding-left: 1em;
  }
}

.structure .depart-info {
  padding-left: 1em;
}

@media screen and (max-width: 767px) {
  .structure .depart-info {
    padding-left: 0;
  }
}

.structure .depart-info__head {
  margin-bottom: 1.2em;
}

.structure .depart-info__head .title-text {
  margin-right: 1em;
}

.structure .depart-info__suit-list {
  margin-bottom: 1.2em;
}

.structure .depart-info__suit {
  display: table;
  width: 100%;
  padding: 1em;
  margin-bottom: 0.5em;
  background-color: #f5f7fa;
}

.structure .depart-info__suit .info-col.title {
  padding-left: 1.25em;
  font-size: 0.75em;
  text-transform: uppercase;
  color: #757575;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__suit .info-col.title {
    padding-left: 0;
  }
}

.structure .depart-info__descr {
  padding: 0 1em 1.2em 1em;
}

.structure .depart-info__descr .title-text {
  float: left;
  padding-left: 1.125em;
  width: 30%;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__descr .title-text {
    float: none;
    width: 100%;
    margin-bottom: 1em;
    padding-left: 0;
  }
}

.structure .depart-info__descr .text-descr {
  float: left;
  width: 70%;
}

@media screen and (max-width: 767px) {
  .structure .depart-info__descr .text-descr {
    float: none;
    width: 100%;
  }
}

.structure .depart-info .title-text {
  color: #666666;
  font-size: 0.875em;
}

.structure .suit-info__descr {
  padding-bottom: 1.2em;
}

.structure .suit-info__descr .title-text {
  float: left;
  padding-left: 1.6em;
  width: 30%;
  color: #666666;
  font-size: 0.875em;
}

@media screen and (max-width: 767px) {
  .structure .suit-info__descr .title-text {
    float: none;
    width: 100%;
    margin-bottom: 1em;
    padding-left: 0;
  }
}

.structure .suit-info__descr .text-descr {
  float: left;
  width: 70%;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .structure .suit-info__descr .text-descr {
    float: none;
    width: 100%;
  }
}

.main-footer {
  margin-top: -360px;
  overflow: hidden;
}

.main-footer .footer-nav-wrap,
.main-footer .footer-info-wrap {
  padding-left: 4em;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-nav-wrap,
  .main-footer .footer-info-wrap {
    padding-left: 0;
  }
}

.main-footer .footer-nav-wrap .row,
.main-footer .footer-info-wrap .row {
  margin-right: 0;
  margin-left: 0;
}

.main-footer .footer-nav-wrap .row .col-md-3,
.main-footer .footer-info-wrap .row .col-md-3 {
  padding-right: 0;
  padding-left: 0;
}

.main-footer .footer-nav-wrap {
  position: relative;
  border-top: 1px solid #bababa;
  overflow-x: hidden;
  overflow-y: visible;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-nav-wrap {
    border-top: none;
  }
}

.main-footer .footer-nav-wrap::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #bababa;
}

.main-footer .footer-nav-wrap .btn-footer-nav {
  display: block;
  width: 4em;
  height: 2.5em;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2.2em;
  color: #2d5ca6;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-nav-wrap .btn-footer-nav {
    position: static;
    margin: 0 auto;
  }
}

.main-footer .footer-nav-wrap .btn-footer-nav.active .icons {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media screen and (max-width: 991px) {
  .main-footer .nav-list {
    text-align: center;
  }
}

.main-footer .nav-list__title {
  position: relative;
  cursor: pointer;
}

.main-footer .nav-list__title::before {
  content: "";
  display: block;
  width: 250%;
  height: 1px;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #bababa;
}

.main-footer .nav-list__title.bottom-line::after {
  z-index: 11;
  background-color: #2d5ca6;
}

@media screen and (max-width: 991px) {
  .main-footer .nav-list__title.bottom-line::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.main-footer .nav-list__title .title-text {
  display: table-cell;
  vertical-align: middle;
  height: 3.333333em;
  font-size: 0.75em;
  font-weight: 500;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  .main-footer .nav-list__title .title-text {
    display: block;
    height: auto;
    padding: 1em 0;
  }
}

.main-footer .nav-list__nav {
  display: none;
}

.main-footer .nav-list__nav ul {
  padding: 1em 0;
}

.main-footer .nav-list__nav ul li a {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 1.5em 0.5em 0;
  font-size: 0.75em;
  color: #666666;
}

@media screen and (max-width: 991px) {
  .main-footer .nav-list__nav ul li a {
    padding-right: 0;
  }
}

.main-footer .nav-list__nav ul li a:hover {
  color: #2d5ca6;
}

.main-footer .footer-info-wrap {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap {
    text-align: center;
  }
}

.main-footer .footer-info-wrap .coat-of-arms {
  position: absolute;
  left: 0.4em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 2.8em;
  line-height: 0.75;
  color: #fbd900;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .coat-of-arms {
    position: static;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    margin-bottom: 0.4em;
  }
}

.main-footer .footer-info-wrap .footer-info-table {
  display: table;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .footer-info-table {
    display: block;
  }
}

.main-footer .footer-info-wrap .footer-info-table .footer-info-cell {
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .footer-info-table .footer-info-cell {
    display: block;
    margin-bottom: 1em;
  }
}

.main-footer .footer-info-wrap .min-fin-text {
  padding-left: 15px;
  padding-right: 10px;
  color: #757575;
  font-size: 0.875em;
}

.main-footer .footer-info-wrap .previous-site-version {
  display: inline-block;
  font-size: 0.875em;
  padding-top: 2.5em;
  padding-left: 15px;
  padding-right: 10px;
}

.main-footer .footer-info-wrap .previous-site-version a {
  color: #2d5ca6;
  font-weight: 500;
}

.main-footer .footer-info-wrap .previous-site-version a:hover {
  text-decoration: underline;
}

.main-footer .footer-info-wrap .mail-list {
  margin-bottom: 0.8em;
  font-weight: 500;
  color: #2d5ca6;
}

.main-footer .footer-info-wrap .mail-list li {
  margin: 0;
}

.main-footer .footer-info-wrap .phone-list {
  font-size: 1.125em;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .phone-list {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .social-list {
    padding: 1em;
  }
}

.main-footer .footer-info-wrap .social-list > a {
  margin-right: 0;
}

.main-footer .footer-info-wrap .btn-wrap {
  padding-left: 2em;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .btn-wrap {
    padding-left: 0;
    white-space: normal;
  }
}

.main-footer .footer-info-wrap .btn-wrap .btn {
  vertical-align: middle;
}

@media screen and (max-width: 1199px) {
  .main-footer .footer-info-wrap .btn-wrap .btn {
    padding: 0.526em 1em 0.725em;
  }
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .btn-wrap .btn {
    width: 100%;
    max-width: 15em;
    margin: 0 1em 1.5em 1em;
  }
}

.main-footer .footer-info-wrap .btn-wrap .btn:first-child {
  margin-right: 2em;
}

@media screen and (max-width: 1199px) {
  .main-footer .footer-info-wrap .btn-wrap .btn:first-child {
    margin-right: 1em;
  }
}

@media screen and (max-width: 991px) {
  .main-footer .footer-info-wrap .btn-wrap .btn:first-child {
    margin-right: 1em;
  }
}

.main-footer .modal .external-links-table .external-link-cell {
  padding: 0.5em 0;
  text-align: center;
}

.main-footer .external-links-wrap {
  padding: 2.0625em 1.5em;
  background-color: #f5f7fa;
}

@media screen and (max-width: 991px) {
  .main-footer .external-links-wrap {
    text-align: center;
  }
}

.main-footer .external-links-wrap .external-links-table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

@media screen and (max-width: 991px) {
  .main-footer .external-links-wrap .external-links-table {
    display: block;
  }
}

.main-footer .external-links-wrap .external-links-table .external-link-cell {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  padding-right: 1em;
}

@media screen and (max-width: 991px) {
  .main-footer .external-links-wrap .external-links-table .external-link-cell {
    display: block;
    text-align: center;
  }
}

.main-footer .external-links-wrap .external-links-table .external-link {
  position: relative;
  display: inline-block;
  padding-left: 1em;
  font-size: 0.875em;
  color: #2d5ca6;
}

@media screen and (max-width: 991px) {
  .main-footer .external-links-wrap .external-links-table .external-link {
    padding: 0.5em 0;
    font-size: 1em;
    font-weight: 500;
  }
}

.main-footer .external-links-wrap .external-links-table .external-link:hover {
  color: #333333;
}

.main-footer .external-links-wrap .external-links-table .external-link::after {
  content: "";
  display: block;
  width: 1px;
  height: 2.5em;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #bababa;
}

@media screen and (max-width: 991px) {
  .main-footer .external-links-wrap .external-links-table .external-link::after {
    content: none;
  }
}

.main-footer .footer-bottom {
  padding: 2.5em 0 3em 0;
  font-size: 0.75em;
  color: #757575;
  text-align: center;
}

