/*===================================================
 Mixins
===================================================*/

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}

@mixin transform($property) {
	-webkit-transform: #{$property};
	-moz-transform: #{$property};
	-ms-transform: #{$property};
	-o-transform: #{$property};
	transform: #{$property};
}

@mixin transition($property, $duration, $easing: linear) {
	transition: $property $duration $easing;
	-webkit-transition: $property $duration $easing;
	-moz-transition: $property $duration $easing;
	-o-transition: $property $duration $easing;
}

@mixin animation($name, $duration, $easing: linear) {
	-webkit-animation: $name $duration $easing;
	-o-animation: $name $duration $easing;
	animation: $name $duration $easing;
}

@mixin box-sizing(){
	box-sizing: border-box; /* css3 rec */
	-moz-box-sizing: border-box; /* ff2 */
	-ms-box-sizing: border-box; /* ie8 */
	-webkit-box-sizing: border-box; /* safari3 */
	-khtml-box-sizing: border-box; /* konqueror */
}

$xxl: 2500px;
$xl: 1920px;
$sl: 1600px;
$lg: 1200px;
$md: 991px;
$sm: 768px;
$xs: 576px;
$iphone5: 420px;

@mixin respond-to($media) {
	@if $media == xxl {
		@media only screen and (min-width: $xxl) { @content; }
	}
	@else if $media == xl {
		@media only screen and (min-width: $xl) { @content; }
	}
	@else if $media == sl {
		@media only screen and (min-width: $sl) { @content; }
	}
	@else if $media == lg {
		@media only screen and (max-width: $lg) { @content; }
	}
	@else if $media == md {
		@media only screen and (max-width: $md) { @content; }
	}
	@else if $media == sm {
		@media only screen and (max-width: $sm) { @content; }
	}
	@else if $media == xs {
		@media only screen and (max-width: $xs) { @content; }
	}
	@else if $media == iphone5 {
		@media only screen and (max-width: $iphone5) { @content; }
	}
}