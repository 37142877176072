
.btn {
    display: inline-block;
    vertical-align: top;
    padding: .813em 2em;

    outline: none;
    border: 2px solid $color-text-blue;

    background-color: transparent;

    font-size: 1em;
    line-height: 1;
    font-weight: 600;
    color: $color-text-blue;
    text-align: center;

    @include transition(all, .2s, ease-in-out);

    @media screen and (max-width: 991px) {
        //padding-left: 1em;
        //padding-right: 1em;
        width: 100%;
        max-width: 15em;
    }

    &:hover {
        border-color: $border-btn-active;
        //background-color: $color-text-blue;
        color: $border-btn-active;
    }
    &:focus, &:active {
        background-color: $border-btn-active;
        border-color: $border-btn-active;
        color: $color-text-img;
    }

    &.fix-height {
        display: table-cell;
        vertical-align: middle;
        height: 2.5em;
        padding: 0 2em;
        line-height: 1;
    }

    &.no-border {
        height: auto;
        padding: 0;
        line-height: 1.25;
        border: none;
        &:hover, &:active, &:focus {
            border: none;
            background-color: transparent;
            color: $color-text-blue;
        }
    }

    &.blue {
        background-color: $bg-btn-blue;
        border-color: $bg-btn-blue;
        color: $color-text-img;
        &:hover {
            background-color: $bg-btn-blue-hover;
            border-color: $bg-btn-blue-hover;
            color: $color-text-img;
        }
        &:focus, &:active {
            background-color: $bg-btn-blue-hover;
            border-color: $border-btn-active;
            color: $color-text-img;
        }
    }

    &.yellow {
        background-color: $bg-btn-yellow;
        border-color: $bg-btn-yellow;
        color: $color-text-base;
        &:hover {
            background-color: $bg-btn-yellow-hover;
            border-color: $bg-btn-yellow-hover;
            color: $color-text-base;
        }
        &:focus, &:active {
            background-color: $bg-btn-yellow-hover;
            border-color: $border-btn-active;
            color: $color-text-base;
        }
    }

    &.full-width {
        width: 100%;
        max-width: none;
    }

    &.pad-4em {
        padding-left: 4em;
        padding-right: 4em;
    }

    &.pad-3em {
        padding-left: 3em;
        padding-right: 3em;
    }

    &__anim {
        position: relative;

        .line-top-left, .line-bottom-right {
            position: absolute;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
        }

        .line-top-left{
            top: -2px;
            left: -2px;
        }

        .line-bottom-right{
            bottom: -2px;
            right: -2px;
        }

        .line-top-left:before,
        .line-top-left:after,
        .line-bottom-right:before,
        .line-bottom-right:after {
            content: "";
            position: absolute;
            z-index: 99;
            background: $border-btn-active;
            -webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
            transition:         all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .line-top-left:before {
            height: 0;
            width: 2px;
            left: 0;
            top: 0;
        }

        .line-top-left:after{
            height: 2px;
            width: 0%;
            left: 0;
            top: 0;
        }

        .line-bottom-right:before {
            height: 0;
            width: 2px;
            right: 0;
            bottom: 0;
        }

        .line-bottom-right:after{
            height: 2px;
            width: 0;
            right: 0;
            bottom: 0;
        }

        &:hover .line-top-left:before,
        &:hover .line-bottom-right:before  {
            height: 100%;
        }

        &:hover .line-top-left:after,
        &:hover .line-bottom-right:after {
            width: 100%;
        }

    }

}

.btn-link {
    position: relative;
    display: inline-block;
    vertical-align: top;

    font-weight: 500;
    line-height: 1;
    color: $color-text-blue;

    &:hover {
        color: $color-text-blue;
        text-decoration: underline;
    }

    .icons {
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
        font-size: 1.2em;
    }

    &.under {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    &.left-icon {
        padding-left: 1.6em;
        .icons {
            left: 0;
        }
    }

    &.right-icon {
        padding-right: 1.6em;
        .icons {
            right: 0;
        }
    }

}

.btn-table-wrap {
    margin-left: -15px;
    margin-right: -15px;

    @media screen and (max-width: 767px) {
        margin-left: -8px;
        margin-right: -8px;
    }

    .btn-list {
        display: table;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 1.8em;
    }

    .btn-table-cel {
        display: table-cell;
        vertical-align: middle;
        height: 6.25em;
        position: relative;
        padding-right: 15px;
        background-color: $bg-grey;
        border-left: 15px solid $color-text-img;
        border-right: 15px solid $color-text-img;
        color: $color-text-blue;
        font-size: 1.125em;
        font-weight: 500;

        @media screen and (max-width: 767px) {
            height: auto;
            padding-top: 1.25em;
            padding-left: 10px;
            padding-right: 10px;
            border-left: 8px solid $color-text-img;
            border-right: 8px solid $color-text-img;
            vertical-align: top;
            text-align: center;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        span {
            display: inline-block;
            padding-left: 34%;
            @media screen and (max-width: 767px) {
                width: 100%;
                padding-left: 0;
                margin-bottom: 1.25em;
            }
        }

        .icons {
            position: absolute;
            top: 50%;
            left: 10%;
            @include transform(translateY(-50%));
            font-size: 2.8em;
            color: $color-table-icon;

            @media screen and (max-width: 1199px) {
                left: 7%;
                font-size: 2.5em;
            }
            @media screen and (max-width: 767px) {
                position: static;
                @include transform(none);
                margin-bottom: 10px;
            }
        }

    }
}