.table-wrap {
    font-size: 1em;

    .table-head {
        margin-bottom: 1em;
        text-align: justify;
        -ms-text-align-last: justify;
        text-align-last: justify;

        @media screen and (max-width: 991px) {
            text-align: center;
            -ms-text-align-last: center;
            text-align-last: center;
        }
        .summary-text {
            display: inline-block;
            vertical-align: bottom;
            color: $color-text-grey;
            font-weight: 500;
            @media screen and (max-width: 991px) {
                margin-bottom: .5em;
            }
        }
        .btn-wrap {
            display: inline-block;
            vertical-align: bottom;
            .btn {
                padding-left: 4em;
                padding-right: 4em;
                @media screen and (max-width: 991px) {
                    padding-left: 1em;
                    padding-right: 1em;
                }
            }
            @media screen and (max-width: 991px) {
                display: block;
            }
        }
    }

    .table-body {
        width: 100%;
        overflow-x: auto;
        @media screen and (max-width: 991px) {
            width: auto;
        }
    }
}

table, .table {
    border-collapse: collapse;

    &__bordered {
        border-collapse: collapse;
        thead {
            background-color: $bg-table-strip;
            tr {
                &:first-child {
                    th {
                        border-top: none;
                    }
                }
            }
            th {
                border: 1px solid $table-border;
            }
        }
        tbody {
            td {
                border-left: 1px solid $table-border;
                border-right: 1px solid $table-border;
            }
        }
    }

    &__striped {
        thead {
            background-color: $bg-table-strip;
        }
        th {
            color: $color-text-grey;
            font-weight: 500;
        }
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: $bg-table-strip;
                }
            }
        }
        &.table__hover {
            tbody {
                tr {
                    &:hover {
                        background-color: $bg-table-strip-hover;
                    }
                }
            }
        }
    }

    &__hover {
        tbody {
            tr {
                &:hover {
                    background-color: $bg-table-strip;
                }
            }
        }
    }

    &__full {
        width: 100%;
        @media screen and (max-width: 767px) {
            width: auto;
        }
    }

    th, td {
        padding: .5em .5em;
        vertical-align: middle;
    }
}

%default-table {
    @extend .table;
    @extend .table__bordered;
    @extend .table__striped;
    @extend .table__full;
}