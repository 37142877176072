.progress{
    position: relative;
    text-align: center;

    .barOverflow{ /* Wraps the rotating .bar */
        position: relative;
        width: 11.25em; height: 5.625em; /* Half circle (overflow) */
        margin: 0 auto;
        //margin-bottom: -1px; /* bring the numbers up */
        overflow: hidden; /* Comment this line to understand the trick */
    }

    .bar{
        position: absolute;
        top: 0; left: 0;
        width: 11.25em; height: 11.25em; /* full circle! */
        border-radius: 50%;
        box-sizing: border-box;
        border: 2.125em solid $color-text-img;     /* half gray, */
        border-bottom-color: $color-text-blue;  /* half azure */
        border-right-color: $color-text-blue;

        @include transform(rotate(45deg));
    }

    .progress-val {
        position: absolute;
        left: 50%;
        bottom: .6em;
        @include transform(translate(-50%));

        font-size: 1.5em;
        font-weight: 700;
        color: $color-text-blue;
    }

    .progress-note {
        width: 11.25em;
        margin: 0 auto;
        padding-top: .125em;
        padding-left: .8em;
        padding-right: .35em;

        text-align: justify;
        -ms-text-align-last: justify;
        text-align-last: justify;
        span {
            font-size: .875em;
        }
    }

}