.social-list {
    padding: 0 1em;
    white-space: nowrap;
    > a {
        display: inline-block;
        width: 1.7em;
        height: 1.7em;
        margin-right: 0.5em;
        text-align: center;
        color: #757575;
        &:hover {
            color: $color-text-blue;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}