@font-face {
	font-family: "icons";
	src: url("/assets/fonts/icons/icons.eot?ccb8553939873d9e0990043c4c9f6029?#iefix") format("embedded-opentype"),
url("/assets/fonts/icons/icons.woff?38307d5693f641d97ff4dd19705da7c5") format("woff"),
url("/assets/fonts/icons/icons.woff2?3b79a84d742820ee8a5061160886a245") format("woff2"),
url("/assets/fonts/icons/icons.ttf?fc2a03916203255e52996f757a9a7fc3") format("truetype"),
url("/assets/fonts/icons/icons.svg?7d56d34f36e2e8bd2875d1be8db0bbb9#icons") format("svg");
}

.icons {
	vertical-align:middle;
	line-height:1em;
	display:inline-block;
}

.icons:before {
	font-family: icons !important;
	font-style: normal;
	font-weight: normal !important;
	display:block;
}

.icon-arrow-big-left:before {
	content: "\f101";
}
.icon-arrow-big-right:before {
	content: "\f102";
}
.icon-arrow-bottom:before {
	content: "\f103";
}
.icon-arrow-circle-left:before {
	content: "\f104";
}
.icon-arrow-circle-right:before {
	content: "\f105";
}
.icon-arrow-left:before {
	content: "\f106";
}
.icon-arrow-right:before {
	content: "\f107";
}
.icon-arrow-round-left:before {
	content: "\f108";
}
.icon-arrow-round-right:before {
	content: "\f109";
}
.icon-auction:before {
	content: "\f10a";
}
.icon-calendar:before {
	content: "\f10b";
}
.icon-clock:before {
	content: "\f10c";
}
.icon-close-x:before {
	content: "\f10d";
}
.icon-coat-of-arms:before {
	content: "\f10e";
}
.icon-cube:before {
	content: "\f10f";
}
.icon-debt-calendar:before {
	content: "\f110";
}
.icon-debt-invrelations:before {
	content: "\f111";
}
.icon-download:before {
	content: "\f112";
}
.icon-facebook:before {
	content: "\f113";
}
.icon-file-doc-hover:before {
	content: "\f114";
}
.icon-file-doc:before {
	content: "\f115";
}
.icon-file-jpg-hover:before {
	content: "\f116";
}
.icon-file-jpg:before {
	content: "\f117";
}
.icon-file-pdf-hover:before {
	content: "\f118";
}
.icon-file-pdf:before {
	content: "\f119";
}
.icon-file-ppt-hover:before {
	content: "\f11a";
}
.icon-file-ppt:before {
	content: "\f11b";
}
.icon-file-rar-hover:before {
	content: "\f11c";
}
.icon-file-rar:before {
	content: "\f11d";
}
.icon-file-tiff-hover:before {
	content: "\f11e";
}
.icon-file-tiff:before {
	content: "\f11f";
}
.icon-file-xls-hover:before {
	content: "\f120";
}
.icon-file-xls:before {
	content: "\f121";
}
.icon-file-zip-hover:before {
	content: "\f122";
}
.icon-file-zip:before {
	content: "\f123";
}
.icon-header-btns-bank:before {
	content: "\f124";
}
.icon-header-btns-calculations:before {
	content: "\f125";
}
.icon-header-btns-contract:before {
	content: "\f126";
}
.icon-header-btns-file:before {
	content: "\f127";
}
.icon-header-btns-time-management:before {
	content: "\f128";
}
.icon-hide:before {
	content: "\f129";
}
.icon-information:before {
	content: "\f12a";
}
.icon-instagram:before {
	content: "\f12b";
}
.icon-language:before {
	content: "\f12c";
}
.icon-linkedin:before {
	content: "\f12d";
}
.icon-money:before {
	content: "\f12e";
}
.icon-photo-camera:before {
	content: "\f12f";
}
.icon-reload:before {
	content: "\f130";
}
.icon-search:before {
	content: "\f131";
}
.icon-spinner:before {
	content: "\f132";
}
.icon-strategy:before {
	content: "\f133";
}
.icon-telegram:before {
	content: "\f134";
}
.icon-twitter:before {
	content: "\f135";
}
.icon-viber:before {
	content: "\f136";
}
.icon-video-btn:before {
	content: "\f137";
}
.icon-youtube:before {
	content: "\f138";
}
