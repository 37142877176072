.more-info {

    &.open {
        .more-info__title {
            color: $color-text-blue;
        }
        .more-info__btn-wrap {
            .btn-link {
                .icons {
                    &::before {
                        @include transform(rotate(180deg));
                    }
                }
            }
            .btn-link-open {
                display: none;
            }
            .btn-link-close {
                display: inline-block;
                .icons {
                    &::before {
                        @include transform(rotate(180deg));
                    }
                }
            }
        }
        .more-info__body {
            display: block;
        }
    }

    &__title {
        cursor: pointer;
        &:hover {
            color: $color-text-blue;
            ~ .more-info__btn-wrap .btn-link {
                text-decoration: underline;
            }
        }
    }

    &__body {
        display: none;
        padding: 1em 0 1em 2em;
        @media screen and (max-width: 767px) {
            padding-left: 0;
        }
    }

    &__btn-wrap {
        .btn-link {
            padding-right: 1.3em;
            .icons {
                font-size: .75em;
            }
        }
        .btn-link-close {
            display: none;
        }
    }

}