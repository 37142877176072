%table-line {

    .info-col {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        height: 2.5em;
        padding: 0 .5em;

        @media screen and (max-width: 767px) {
            display: block;
            height: auto;
        }

        &::before {
            content: '';
            display: block;
            width: 1px;
            height: 1.5em;
            position: absolute;
            top: 50%;
            left: 0;
            @include transform(translateY(-50%));
            background-color: $color-secondary;
            @media screen and (max-width: 767px) {
                content: none;
            }
        }
        &:first-child {
            &::before {
                content: none;
            }
        }
        &.title {
            width: 30%;
            padding-left: 2.8em;

            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 1em;
            }

            &.btn-link {
                vertical-align: middle;
            }
            &.title-full {
                width: 100%;
                height: 3.3em;
                @media screen and (max-width: 767px) {
                    height: auto;
                    margin-bottom: 0;
                }
            }
        }
        &.name {
            width: 45%;
            padding-left: 1em;

            @media screen and (max-width: 767px) {
                width: 100%;
                padding-left: 0;
                margin-bottom: .5em;
            }

            > span {
                font-size: 1.25em;
            }
        }
        &.phones-wrap {
            width: 25%;
            padding-left: 2em;
            white-space: nowrap;
            color: $color-table-icon;

            @media screen and (max-width: 767px) {
                width: 100%;
                padding-left: 0;
            }

            .phones {
                font-size: .875em;
                line-height: 1.5;
            }
        }
    }

}

.structure {

    &__header {
        margin-bottom: 2em;
        .help-text {
            float: left;
            color: $color-text-grey;
            margin-bottom: 1em;

            @media screen and (max-width: 767px) {
                float: none;
            }

        }
        > .btn {
            float: right;

            @media screen and (max-width: 767px) {
                float: none;
            }

        }
    }

    > .accordion {
        > .accordion__item {
            &.open {
                border-left: none;
                > .accordion__head {
                    background-color: $color-text-blue;
                }
            }
            > .accordion__head {
                background-color: $color-text-blue;
                .info-col {
                    height: 2.2em;
                    &.title {
                        width: 100%;
                        @media screen and (max-width: 767px) {
                            margin-bottom: 0;
                            height: auto;
                            vertical-align: baseline;
                        }
                    }
                }
                .title {
                    color: $color-text-img;
                    //vertical-align: baseline;
                    width: auto;
                    .icons {
                        border: 2px solid rgba(255, 255, 255, 0.6);
                    }
                }
            }
            > .accordion__body {
                .accordion__head {
                    padding-top: 1em;
                    padding-bottom: 1em;
                }
            }
        }
    }

    .accordion__item {
        margin-top: 1em;
        margin-bottom: 0;
        &:first-child {
            margin-top: 0;
        }
        &.open {
            border-left: 1px solid #e6ebf4;
            > .accordion__head {
                background-color: #e6ebf4;
            }
        }
    }

    .accordion__head {
        display: table;
        width: 100%;
        @extend %table-line;
    }

    .accordion__body {
        padding: 1em 0em 0em 2em;

        @media screen and (max-width: 767px) {
            padding-left: 1em;
        }

    }

}

.structure {

    .depart-info {
        padding-left: 1em;

        @media screen and (max-width: 767px) {
            padding-left: 0;
        }

        &__head {
            margin-bottom: 1.2em;
            .title-text {
                margin-right: 1em;
            }
        }

        &__suit-list {
            margin-bottom: 1.2em;
        }
        
        &__suit {
            display: table;
            width: 100%;
            padding: 1em;
            margin-bottom: .5em;
            background-color: $bg-grey;
            @extend %table-line;

            .info-col {
                &.title {
                    padding-left: 1.25em;
                    font-size: .75em;
                    text-transform: uppercase;
                    color: $color-text-grey;

                    @media screen and (max-width: 767px) {
                        padding-left: 0;
                    }

                }
            }

        }

        &__descr {
            padding: 0 1em 1.2em 1em;
            .title-text {
                float: left;
                padding-left: 1.125em;
                width: 30%;

                @media screen and (max-width: 767px) {
                    float: none;
                    width: 100%;
                    margin-bottom: 1em;
                    padding-left: 0;
                }

            }
            .text-descr {
                float: left;
                width: 70%;

                @media screen and (max-width: 767px) {
                    float: none;
                    width: 100%;
                }

            }
        }

        .title-text {
            color: $color-table-icon;
            font-size: .875em;
        }

    }

    .suit-info {

        &__descr {
            padding-bottom: 1.2em;
            .title-text {
                float: left;
                padding-left: 1.6em;
                width: 30%;
                color: $color-table-icon;
                font-size: .875em;

                @media screen and (max-width: 767px) {
                    float: none;
                    width: 100%;
                    margin-bottom: 1em;
                    padding-left: 0;
                }

            }
            .text-descr {
                float: left;
                width: 70%;
                line-height: 1.5;

                @media screen and (max-width: 767px) {
                    float: none;
                    width: 100%;
                }

            }
        }

    }

}