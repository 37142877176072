%date {
    color: $color-text-grey;
    .icons {
        margin-right: .5em;
    }
    > * {
        vertical-align: middle;
    }
}

%placeholder-style {
    &::-webkit-input-placeholder {
        font-size: 1em;
        color: $color-text-secondary-form;
    }
    &:-moz-placeholder {
        font-size: 1em;
        color: $color-text-secondary-form;
    }
    &::-moz-placeholder {
        font-size: 1em;
        color: $color-text-secondary-form;
    }
    &:-ms-input-placeholder {
        font-size: 1em;
        color: $color-text-secondary-form;
    }
}

%round-link-icon {
    .icons {
        top: 50%;
        width: 1.1em;
        height: 1.1em;
        border: 2px solid $border-round-link-icon;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        text-align: center;
        &::before {
            font-size: 1.5em;
            @include transform(translateX(-.25em));
        }
    }
}